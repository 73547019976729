<template>
  <div class="tags">
    <van-cell-group>
      <van-field
        placeholder="请输入账号"
        bind:change="onChange"
        v-model="account"
        label="账号"
        @blur="queryUser"
        :maxlength="11"
      />
      <van-field
        placeholder="请输入姓名"
        bind:change="onChange"
        v-model="name"
        label="姓名"
        readonly
      />
      <van-field
        placeholder="变更理由"
        bind:change="onChange"
        v-model="remark"
        label="变更理由"
        type="textarea"
      />
      <!--选择客服-->
      <customer @cusValue="chooseCus" />
      <approveUser @approveValue="approveValue" />
    </van-cell-group>
    <div class="footer">
      <van-button type="info" size="large" :square="true" @click="changeCus()" :disabled="dis"
        >更改</van-button
      >
    </div>
  </div>
</template>

<script>
import approveUser from "../../compents/approveUser.vue";
export default {
  components: { approveUser },
  data() {
    return {
      account: "", //账号
      name: "", //姓名
      remark: "", //变更理由
      userId: "", //用户id
      cusValue: "", //客服id
      approveUser: 0,
      dis:false
    };
  },
  methods: {
    //客服组件赋值
    chooseCus(val) {
      this.cusValue = val;
    },
    //客服变更
    changeCus() {
      if (!this.account) {
        this.$toast("请输入正确的手机号");
        return;
      }
      this.$axios
        .post(`${this.$base}/fission/lbEnterpriseWeChatUser/applyEdit`, {
          enterpriseWeChatUserId: this.cusValue,
          customerUserId: this.userId,
          approveUser: this.approveUser,
          remark: this.remark,
        })
        .then((res) => {
          if (res.code === "200") {
            this.dis=true
            this.$toast("操作成功");
          }
        });
    },
    queryUser() {
      var myreg = /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
      if (!myreg.test(this.account) || !this.account) {
        this.name = "";
        this.$toast("请输入正确的手机号");
        return;
      }
      this.$axios
        .get(`${this.$base}/admin/lb/user?account=${this.account}`)
        .then((res) => {
          if (res.code === 200) {
            this.account = res.data.account;
            this.name = res.data.nickname;
            this.userId = res.data.id;
          }
        });
    },
    //审批人
    approveValue(val) {
      this.approveUser = val;
    },
  },
};
</script>
<style scoped>
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}
</style>
